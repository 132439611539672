import React from "react"

const SectionAction = props => {
  return (
    <section className={`container sectionAction${props.second ? " second" : ""}`}>
      <div className="sectionAction-block">
        <div className="topBorder"></div>
        <h2 dangerouslySetInnerHTML={{ __html: props.text }}></h2>
        <a href={props.buttonLink ? props.buttonLink : "#"} className="btn btn-page">
          {props.buttonText}
        </a>
      </div>
    </section>
  )
}

export default SectionAction
