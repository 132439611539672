import React from "react"

import Layout from "../hoc/Layout"
import SectionIntro from "../components/Sections/SectionIntro/SectionIntro"
import SectionAction from "../components/Sections/SectionAction/SectionAction"

import LogoBooksellers from "../assets/images/about-us/network-booklovers/children_Booksellers_Worldwide.svg"

const support = () => {
  return (
    <>
      <Layout headerPages pages>
        <SectionIntro page="support" />

        <div className="support">
          <section className="container support-list">
            <div className="row">
              <div className="col-6 col-md-12 support-list-left separate">
                <h2>What we offer</h2>
                <ul className="moonwood-list-style-type no-style-type">
                  <li>Support in setting up a legal business in your country</li>
                  <li>Step-by-step approach for booksellers starting out in their career</li>
                  <li>Guidance in selecting the right titles for your target customer group</li>
                  <li>Advice on preparing orders</li>
                  <li>Product knowledge support</li>
                  <li>Ideas on how to promote your book business online</li>
                  <li>Inspiration on setting up your own bookshop or showroom</li>
                  <li>Tips on selling books to customers</li>
                  <li>Suggestions as to how to increase sales at local events</li>
                  <li>Informative guide on providing successful school book fairs</li>
                  <li>Innovative approaches – bringing books to a wider audience</li>
                  <li>Encouragement and motivation</li>
                  <li>Support from an international community of booksellers online, throughout&nbsp;Europe&nbsp;and worldwide</li>
                </ul>
              </div>

              <div className="col-6 col-md-12 support-list-right">
                <h2>Networking possibilities</h2>
                <p>
                  Members of Children's Booksellers Worldwide have an opportunity of meeting new colleagues on the Facebook Group 'Children's Booksellers Worldwide' – one of the most valuable support systems offered. Here booksellers benefit from the opportunity to share their
                  experience, ask questions and learn new approaches to bookselling. Inspiration is provided for exploring new avenues in sales and marketing, and all with as much creativity encouraged as possible. Booksellers benefit from online training sessions and events,
                  provided to support them as they build their bookselling businesses in their chosen areas.
                </p>
                <img src={LogoBooksellers} alt="LogoChildrenBooksellersWorldwide" />
              </div>
            </div>
          </section>

          <SectionAction text="Would you like to request business support?" buttonText="Learn more" buttonLink="mailto:info@moonwood-books.com?subject=Requesting business support" />
        </div>
      </Layout>
    </>
  )
}

export default support
